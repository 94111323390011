<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search startups"
            type="text"
          />
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="startups"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                dropright
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                    class="text-body align-middle"
                    icon="MoreVerticalIcon"
                    size="16"
                  />
                </template>
                <b-dropdown-item
                  @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModal')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="EyeIcon"
                  />
                  <span>View Details</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon
                    class="mr-50"
                    icon="BarChartIcon"
                  />
                  <span>Update Status</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="selectedRow = props.row.originalIndex; $bvModal.show('sendMessageModel')"
                >
                  <feather-icon
                    class="mr-50"
                    icon="SendIcon"
                  />
                  <span>Send Message</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        v-if="startups[selectedRow] && Object.prototype.hasOwnProperty.call(startups[selectedRow], 'description') "
        id="requestDetailsModal"
        hide-footer
        title="Request Details"
      >
        <div>
          <p>
            {{ startups[selectedRow].description }}
          </p>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>

import {
  BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import vSelect from 'vue-select'

export default {
  components: {

    BFormInput,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // vSelect,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Startup Title',
          field(row) {
            return row.usersAssociationtableByRequester.users_organizationtable.title
          },
        },
        {
          label: 'Mentor Status',
          field: 'assignee_status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Purpose',
          field: 'title',
        },
        {
          label: 'Priority',
          field: 'priority',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Created on',
          field: 'created_at',
          formatFn(date) {
            return new Date(date).toLocaleDateString()
          },
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-capitalize',
        },
      ],
      startups: [],
    }
  },
  apollo: {
    startups: {
      query() {
        return gql`
           query startup_assignment_in_mentor {
            requesttable(where: {type: {_eq: "mentor"}, assignee_id: {_eq: ${this.$route.params.id}}}) {
              created_at
              description
              priority
              status
              title
              usersAssociationtableByRequester {
                users_organizationtable {
                  title
                }
              }
              assignee_status
            }
         }`
      },
      update: data => data.requesttable,
    },
  },
}
</script>
